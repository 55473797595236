import { useEffect, useState } from "react";
import style from "./pdf-style.module.scss";
import Overview from "./components/Overview";
import Header from "./components/Header";
import TableDetails from "./components/TableDetails";
import {
  borderIncomeBuilderUI,
  detailsBuilderUI,
  liabilityBuilderUI,
  outgoingCCBuilderUI,
  expensesBuilderUI,
  rentalIncomeBuilderUI,
  salaryIncomeBuilderUI,
  salaryOtherIncomeBuilderUI,
  securityBuilderUI,
  generateLoanAmountApproval,
  reportBuilderUI,
} from "./lib/builder";
import SectionTitle from "./components/SectionTitle";
import TableList from "./components/TableList";
import LenderTable from "./components/LenderTable";
import LenderTableList from "./components/LenderTableList";
import { IPdfReport } from "./models/report";

// import data from "./sample-data/full-data.json";
import { LoanAmountApprovalBar } from "../../result";

declare global {
  interface Window {
    injectReportData: any;
  }
}

const PdfGenerator = () => {
  const [report, setReport] = useState<IPdfReport>();
  const [loanAmountApproval, setLoanAmountApproval] = useState<any>();
  const [activeLenders, setActiveLenders] = useState<any>();
  const [isMultipleLoans, setIsMultipleLoans] = useState(false);

  useEffect(() => {
    window.injectReportData = (data: IPdfReport) => {
      const reportData = reportBuilderUI(data);
      if (reportData?.resReportData && reportData?.resReportData?.report?.id) {
        const _loanAmountApproval = generateLoanAmountApproval(
          reportData.resReportData
        );
        setLoanAmountApproval(_loanAmountApproval);
        setActiveLenders(
          _loanAmountApproval.find(
            (_loan: any) => _loan.LoanAmount === reportData?.currentLoanAmount
          )
        );
        setIsMultipleLoans(
          reportData?.resReportData?.report?.cases?.length === 1
        );
      }
      setReport(reportData);
    };
  }, []);

  let sectionCount = 1;
  const hasOutgoing =
    Boolean(
      report?.reqReportData?.details?.applicantParty?.find(
        (_exp: any) => _exp.expense?.length
      )?.applicant?.length
    ) ||
    Boolean(
      report?.reqReportData?.details?.applicantParty?.find(
        (_exp: any) => _exp.liability?.length
      )?.applicant?.length
    );

  return (
    <div className={style["pdf-wrapper"]}>
      <div className={style["pdf-container"]}>
        <Header requestId={report?.reportId} />

        <SectionTitle no={sectionCount++} title="Overview" />
        <Overview
          loanAmount={report?.currentLoanAmount ?? 0}
          lvr={report?.resReportData?.report?.accuracy ?? 0}
        />
        {!isMultipleLoans && loanAmountApproval?.length > 0 && (
          <div
            style={{ marginBottom: "20px", height: "170px" }}
            className={style["bar-chart"]}
          >
            <LoanAmountApprovalBar
              loanAmountApproval={loanAmountApproval}
              activeLenders={activeLenders}
              onBarClick={() => {}}
              isForPDF={true}
            />
          </div>
        )}
        {activeLenders?.Pass?.length > 0 && (
          <LenderTableList isApprove={true} list={activeLenders?.Pass} />
        )}
        {activeLenders?.Fail?.length > 0 && (
          <LenderTableList list={activeLenders?.Fail} />
        )}

        <SectionTitle no={sectionCount++} title="Your Details" />
        {report?.reqReportData.details?.applicantParty?.map(
          (_item: any, idx: number) => (
            <TableDetails
              title={`Applicant Details`}
              data={detailsBuilderUI(_item)}
              key={`details-${idx}`}
            />
          )
        )}

        <SectionTitle no={sectionCount++} title="Security" />
        {report?.reqReportData.details?.loan?.map((_item: any, idx: number) => (
          <TableDetails
            title={`Security ${idx + 1}`}
            data={securityBuilderUI(_item)}
            key={`security-${idx}`}
          />
        ))}

        <SectionTitle no={sectionCount++} title="Income" />
        {report?.reqReportData.details?.applicantParty?.map(
          (applicant: any, idx: number) => (
            <TableList
              title={`Salary / Wages`}
              column={[
                {
                  title: "Applicant",
                  key: "applicant",
                },
                {
                  title: "Type",
                  key: "type",
                },
                {
                  title: "Amount",
                  key: "amount",
                  align: "right",
                },
                {
                  title: "Kiwisaver",
                  key: "kiwisaver",
                },
              ]}
              data={salaryIncomeBuilderUI(
                applicant.income.filter(
                  (_i: any) => _i.incomeType === "SalaryWages"
                ),
                applicant.applicant
              )}
              key={`salary-${idx}`}
              style={{ marginBottom: "20px" }}
            />
          )
        )}
        {report?.reqReportData.details?.applicantParty?.map(
          (applicant: any) => {
            return applicant.income
              .filter((_i: any) => _i.incomeType === "RentalIncome")
              .map((_item: any, idx: number) => (
                <TableDetails
                  title={`Rental Income ${idx + 1}`}
                  data={rentalIncomeBuilderUI(_item, applicant.applicant)}
                  key={`rental-${idx}`}
                />
              ));
          }
        )}
        {report?.reqReportData.details?.applicantParty?.map(
          (applicant: any) => {
            return applicant.income
              .filter((_i: any) => _i.incomeType === "BoarderIncome")
              .map((_item: any, idx: number) => (
                <TableDetails
                  title={`Border Income ${idx + 1}`}
                  data={borderIncomeBuilderUI(_item, applicant.applicant)}
                  key={`border-${idx}`}
                />
              ));
          }
        )}
        {report?.reqReportData.details?.applicantParty?.map(
          (applicant: any, idx: number) => (
            <>
              {applicant.income.filter(
                (_i: any) =>
                  _i.incomeType !== "BoarderIncome" &&
                  _i.incomeType !== "RentalIncome" &&
                  _i.incomeType !== "SalaryWages"
              ).length > 0 && (
                <TableList
                  title={`Other Income`}
                  column={[
                    {
                      title: "Applicant",
                      key: "applicant",
                    },
                    {
                      title: "Type",
                      key: "type",
                    },
                    {
                      title: "GrossAmount",
                      key: "amount",
                      align: "right",
                    },
                  ]}
                  data={salaryOtherIncomeBuilderUI(
                    applicant.income.filter(
                      (_i: any) =>
                        _i.incomeType !== "BoarderIncome" &&
                        _i.incomeType !== "RentalIncome" &&
                        _i.incomeType !== "SalaryWages"
                    ),
                    applicant.applicant
                  )}
                  key={`other-${idx}`}
                  style={{ marginBottom: "20px" }}
                />
              )}
            </>
          )
        )}

        {hasOutgoing && (
          <>
            <SectionTitle no={sectionCount++} title="Outgoings" />
            {report?.reqReportData.details?.applicantParty?.map(
              (applicant: any, idx: number) => (
                <>
                  {applicant.liability.filter((_i: any) => !_i.repaymentAmount)
                    .length > 0 && (
                    <TableList
                      title={`Credit Cards`}
                      column={[
                        {
                          title: "Type",
                          key: "type",
                        },
                        {
                          title: "Limit",
                          key: "limit",
                          align: "right",
                        },
                        {
                          title: "Lender",
                          key: "lender",
                        },
                      ]}
                      data={outgoingCCBuilderUI(
                        applicant.liability.filter(
                          (_i: any) => !_i.repaymentAmount
                        )
                      )}
                      key={`cc-${idx}`}
                      style={{ marginBottom: "20px" }}
                    />
                  )}
                </>
              )
            )}

            {report?.reqReportData.details?.applicantParty?.map(
              (applicant: any, idx: number) => (
                <>
                  <TableList
                    title={`Expenses`}
                    column={[]}
                    data={[]}
                    key={`expenses-${idx}`}
                  />
                  {Object.keys(
                    applicant.expense.reduce((acc: any, _exp: any) => {
                      if (!acc[_exp.group]) {
                        acc[_exp.group] = [];
                      }
                      acc[_exp.group].push({ ..._exp });

                      return acc;
                    }, {})
                  ).map((_item: any, _idx: number) => (
                    <TableList
                      title={``}
                      column={[
                        {
                          title: _item,
                          key: "type",
                        },
                        {
                          title: "Amount",
                          key: "amount",
                          align: "right",
                        },
                      ]}
                      data={expensesBuilderUI(applicant.expense, _item)}
                      key={`${_item}-${_idx}`}
                    />
                  ))}
                </>
              )
            )}

            <div style={{ marginTop: "10px" }}>
              {report?.reqReportData.details?.applicantParty?.map(
                (applicant: any) => {
                  return applicant.liability
                    .filter((_i: any) => _i.repaymentAmount)
                    .map((_item: any, idx: number) => (
                      <TableDetails
                        title={`Liabilities ${idx + 1}`}
                        data={liabilityBuilderUI(_item)}
                        key={`liability-${idx}`}
                      />
                    ));
                }
              )}
            </div>
          </>
        )}

        {activeLenders?.Pass?.length > 0 && (
          <>
            <table
              className={style["avoid-break"]}
              style={{ width: "100%", border: 0 }}
            >
              <tbody className={style["avoid-break"]} style={{ border: 0 }}>
                <tr className={style["avoid-break"]} style={{ border: 0 }}>
                  <td className={style["avoid-break"]} style={{ border: 0 }}>
                    <SectionTitle
                      no={sectionCount++}
                      title="Lenders That May Approve"
                    />
                    {activeLenders?.Pass?.map((lender: any, idx: number) => {
                      return <LenderTable data={lender} />;
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {activeLenders?.Fail?.length > 0 && (
          <>
            <table
              className={style["avoid-break"]}
              style={{ width: "100%", border: 0 }}
            >
              <tbody className={style["avoid-break"]} style={{ border: 0 }}>
                <tr className={style["avoid-break"]} style={{ border: 0 }}>
                  <td className={style["avoid-break"]} style={{ border: 0 }}>
                    <SectionTitle
                      no={sectionCount++}
                      title="Lenders That May Decline"
                    />
                    {activeLenders?.Fail?.map((lender: any, idx: number) => {
                      return <LenderTable data={lender} />;
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {report?.notes && (
          <>
            <table style={{ width: "100%", border: 0 }}>
              <tbody style={{ border: 0 }}>
                <tr style={{ border: 0 }}>
                  <td style={{ border: 0 }}>
                    <SectionTitle no={sectionCount++} title="Notes" />
                    <div className={style["lender-notes"]}>
                      <p>{report?.notes}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default PdfGenerator;
