import { FC } from "react";
import { Check, NotApplicable, WarningCircleSm } from "../../../icons";
import { twMerge } from "tailwind-merge";
import style from "../pdf-style.module.scss";

const LenderTableList: FC<{
  list: any;
  isApprove?: boolean;
}> = (props) => {
  // console.log(props.list, "<=== Lender Table List data");

  return (
    <div
      className={style["lender-table-list"]}
      style={{ marginBottom: "20px" }}
    >
      <table>
        <tbody>
          <tr className={style["table-header"]}>
            <td
              style={{
                width: "30%",
              }}
              align="left"
            >
              <p>
                LENDERS THAT MAY
                <span
                  style={{
                    color: `${props?.isApprove ? "#41b4b4" : "#FF6E6E"}`,
                  }}
                >
                  {props?.isApprove ? "APPROVE" : "DECLINE"}
                </span>
              </p>
            </td>
            <td
              style={{
                width: "30%",
              }}
              align="center"
            >
              <p>Servicing</p>
            </td>
            <td
              style={{
                width: "20%",
              }}
              align="center"
            >
              <p>LVR</p>
            </td>
            <td
              style={{
                width: "20%",
              }}
              align="center"
            >
              <p>DTI</p>
            </td>
          </tr>
          {props.list?.map((_lender: any, idx: any) => {
            const servicingStatus =
              !_lender.result?.servicing ||
              _lender.result?.servicing.type === "N/A"
                ? _lender.result?.UMI?.status
                : _lender.result?.servicing.status;

            const hasTopError = !!_lender?.errors?.find(
              (_i: any) => _i.reference === "Top"
            )?.message;

            return hasTopError ? (
              <tr className={style["lender-error"]} key={idx}>
                <td
                  style={{
                    width: "30%",
                    verticalAlign: "top",
                  }}
                  align="left"
                >
                  <p>{_lender?.lender?.name}</p>
                </td>
                <td colSpan={3}>
                  <p className={style["error"]}>
                    <WarningCircleSm />
                    <span>No calculations performed for this lender</span>
                  </p>
                </td>
              </tr>
            ) : (
              <tr key={idx}>
                <td
                  style={{
                    width: "30%",
                  }}
                  align="left"
                >
                  <p>{_lender?.lender?.name}</p>
                </td>
                <td
                  style={{
                    width: "30%",
                  }}
                  align="center"
                >
                  <p>
                    {servicingStatus === "Fail" && (
                      <WarningCircleSm width={16} height={16} fill="#FF6E6E" />
                    )}
                    {servicingStatus === "Pass" && <Check />}
                    {servicingStatus === "N/A" && <NotApplicable />}
                  </p>
                </td>
                <td
                  style={{
                    width: "20%",
                  }}
                  align="center"
                >
                  {_lender?.result?.LVR?.map((_lvr: any, lvrIdx: any) => (
                    <div
                      className={twMerge(
                        style["badge"],
                        _lvr?.status === "Pass" && style["badge-pass"],
                        _lvr?.status === "Fail" && style["badge-fail"],
                        _lvr?.status === "N/A" && style["badge-na"]
                      )}
                      key={`lvr-${lvrIdx}`}
                      style={{
                        display: "inline-block",
                        marginRight: "3px",
                      }}
                    >
                      {_lvr?.value * 100}%
                    </div>
                  ))}
                </td>
                <td
                  style={{
                    width: "20%",
                  }}
                  align="center"
                >
                  <p>
                    {_lender?.result?.DTI?.status === "Pass" ? (
                      <Check />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LenderTableList;
