import { FC } from "react";
import { WarningBubble, WarningCircleSm } from "../../../icons";
import style from "../pdf-style.module.scss";

const LenderErrors: FC<{
  errors: Array<any>;
  warnings: Array<any>;
  reference: string;
  loanId?: number;
}> = ({ errors, warnings, reference, loanId }) => {
  const hasErrors = Boolean(
    errors?.filter(
      (_i: any) =>
        _i.reference === reference && loanId && _i.referenceObjectId === loanId
    )?.length ||
      warnings?.filter((_i: any) => _i.reference === reference)?.length
  );

  return hasErrors ? (
    <div className={style["lender-errors"]}>
      {errors
        ?.filter(
          (_i: any) =>
            _i.reference === reference &&
            loanId &&
            _i.referenceObjectId === loanId
        )
        ?.map((_err: any, _errIdx: number) =>
          _err.message ? (
            <p className={style["error"]} key={`lvr-error-${_errIdx}`}>
              <WarningCircleSm />
              <span>{_err.message}</span>
            </p>
          ) : (
            <></>
          )
        )}
      {warnings
        ?.filter((_i: any) => _i.reference === reference)
        ?.map((_err: any, _errIdx: number) =>
          _err.message ? (
            <p className={style["warning"]} key={`lvr-warning-${_errIdx}`}>
              <WarningBubble width="14px" height="14px" />
              <span>{_err.message}</span>
            </p>
          ) : (
            <></>
          )
        )}
    </div>
  ) : (
    <></>
  );
};

export default LenderErrors;
