import * as DateFns from "date-fns";
import currency from "currency.js";
import { IPdfReport, IResReport } from "../models/report";
import { expenseTypeData } from "../../../expenses";
import { decimalToPercentage } from "../../../util";

export const reportBuilderUI = (report: any): IPdfReport => {
  return {
    reportId: report.reportId,
    currentLoanAmount: report?.currentLoanAmount,
    lenders: report?.lenders,
    includeWarningsErrors: report?.includeWarningsErrors,
    notes: report?.notes,
    reqReportData: requestBuilderUI(report?.reqReportData),
    resReportData: { report: responseBuilderUI(report?.resReportData?.Report) },
  };
};

export const requestBuilderUI = (data: any) => {
  return {
    details: {
      loan: data?.Details?.Loan?.map((_i: any) => ({
        loanId: _i?.LoanId,
        loanType: _i?.LoanType,
        lender: _i?.Lender,
        lendingAction: _i?.LendingAction,
        liabilityType: _i?.LiabilityType,
        loanAmount: _i?.LoanAmount,
        topUpAmount: _i?.TopUpAmount,
        totalTermMonths: _i?.TotalTermMonths,
        interestOnlyTermMonths: _i?.InterestOnlyTermMonths,
        security: {
          value: _i?.Security?.Value,
          isRBNZExempt: _i?.Security?.IsRBNZExempt,
          location: _i?.Security?.Location,
          securityType: _i?.Security?.SecurityType,
          usageType: _i?.Security?.UsageType,
          propertyType: _i?.Security?.PropertyType,
        },
      })),
      applicantParty: data?.Details?.ApplicantParty?.map((_i: any) => ({
        applicant: _i?.Applicant?.map((d: any) => ({
          applicantId: d?.ApplicantId,
          name: d?.Name,
        })),
        numberOfDependents: _i?.NumberOfDependents,
        numberOfVehicles: _i?.NumberOfVehicles,
        expense: _i?.Expense?.map((d: any) => ({
          applicantId: d?.ApplicantId,
          expenseType: d?.ExpenseType,
          frequency: d?.Frequency,
          amount: d?.Amount,
          ...expenseTypeData?.reduce((_acc: any, _expense: any) => {
            if (_expense["ExpenseType.Code"] === d?.ExpenseType) {
              _acc = {
                group: _expense?.Group,
                label: _expense["ExpenseType.Name"],
              };
            }
            return _acc;
          }, {}),
        })),
        income: _i?.Income?.map((d: any) => ({
          applicantId: d?.ApplicantId,
          incomeType: d?.IncomeType,
          amountType: d?.AmountType,
          frequency: d?.Frequency,
          amount: d?.Amount,
          attributes: d?.Attributes,
        })),
        liability: _i?.Liability?.map((d: any) => ({
          applicantId: d?.ApplicantId,
          liabilityType: d?.LiabilityType,
          repaymentAmount: d?.RepaymentAmount,
          repaymentFrequency: d?.RepaymentFrequency,
          limit: d?.Limit,
          balance: d?.Balance,
          interestRate: d?.InterestRate,
          repaymentType: d?.RepaymentType,
          startDate: d?.StartDate,
          totalTermMonths: d?.TotalTermMonths,
          interestOnlyTermMonths: d?.InterestOnlyTermMonths,
          lender: d?.Lender,
          isRevolvingCredit: d?.IsRevolvingCredit,
        })),
      })),
    },
    lenders: data?.Lenders,
  };
};

export const responseBuilderUI = (data: any) => {
  return {
    id: data?.Id,
    cases: data?.Cases?.map((_case: any) => ({
      id: _case?.Id,
      loanAmount: _case?.LoanAmount,
      lenderResult: _case?.LenderResult?.map((_d: any) => ({
        lender: {
          id: _d?.Lender?.Id,
          key: _d?.Lender?.Key,
          name: _d?.Lender?.Name,
          logo: _d?.Lender?.Logo,
        },
        result: {
          assessedIncome: _d?.Result?.AssessedIncome?.map((_i: any) => ({
            group: _i?.Group,
            label: _i?.Label,
            entered: {
              amount: _i?.Entered?.Amount,
              frequency: _i?.Entered?.Frequency,
            },
            assessed: {
              amount: _i?.Assessed?.Amount,
              frequency: _i?.Assessed?.Frequency,
            },
          })),
          assessedExpense: _d?.Result?.AssessedExpense?.map((_i: any) => ({
            group: _i?.Group,
            label: _i?.Label,
            entered: {
              amount: _i?.Entered?.Amount,
              interestRate: _i?.Entered?.InterestRate,
            },
            assessed: {
              amount: _i?.Assessed?.Amount,
              frequency: _i?.Assessed?.Frequency,
            },
          })),
          totalMonthlyIncome: _d?.Result?.TotalMonthlyIncome,
          totalMonthlyExpense: _d?.Result?.TotalMonthlyExpense,
          UMI: {
            value: _d?.Result?.UMI?.Value,
            required: _d?.Result?.UMI?.Required,
            status: _d?.Result?.UMI?.Status,
            securityUsageType: _d?.Result?.UMI?.SecurityUsageType,
            lvrValue: _d?.Result?.UMI?.LvrValue,
          },
          servicing: {
            type: _d?.Result?.Servicing?.Type,
            valuetype: _d?.Result?.Servicing?.Value,
            required: _d?.Result?.Servicing?.Required,
            status: _d?.Result?.Servicing?.Status,
          },
          LVR: _d?.Result?.LVR?.map((_i: any) => ({
            reference: _i?.Reference,
            loanId: _i?.LoanId,
            lvrLimit: _i?.LVRLimit?.map((_j: any) => ({
              label: _j?.Label,
              description: _j?.Description,
              maxLVR: _j?.MaxLVR,
            })),
            value: _i?.Value,
            requiredMaxLVR: _i?.RequiredMaxLVR,
            status: _i?.Status,
          })),
          LEM: _d?.Result?.LEM?.map((_i: any) => ({
            label: _i?.Label,
            loanId: _i?.LoanId,
            assessed: _i?.Assessed,
            margin: _i?.Margin,
            amount: _i?.Amount,
          })),
          DTI: {
            totalIncome: _d?.Result?.DTI?.TotalIncome,
            totalDebt: _d?.Result?.DTI?.TotalDebt,
            value: _d?.Result?.DTI?.Value,
            requiredMaxDTI: _d?.Result?.DTI?.RequiredMaxDTI,
            status: _d?.Result?.DTI?.Status,
          },
          deposit: {
            amount: _d?.Result?.Deposit?.Amount,
          },
        },
        warnings: _d?.Warnings?.map((_i: any) => ({
          message: _i?.Message,
          reference: _i?.Reference,
        })),
        errors: _d?.Errors?.map((_i: any) => ({
          message: _i?.Message,
          reference: _i?.Reference,
          referenceObjectType: _i?.ReferenceObjectType,
          referenceObjectId: _i?.ReferenceObjectId,
        })),
        resources: _d?.Resources?.map((_i: any) => ({
          type: _i?.Type,
          reference: _i?.Reference,
          url: _i?.URL,
        })),
        accuracy: _d?.Accuracy,
        version: {
          versionNumber: _d?.Version?.VersionNumber,
          releaseDate: _d?.Version?.ReleaseDate,
          versionReleaseNote: _d?.Version?.VersionReleaseNote,
        },
      })),
    })),
    accuracy: data?.Accuracy,
  };
};

export const generateLoanAmountApproval = (data: IResReport) => {
  const loanAmountApproval = data?.report?.cases?.reduce<any>(
    (acc: any, _case: any) => {
      const { Pass, Fail } = _case?.lenderResult?.reduce(
        (a: any, current: any) => {
          const { result, errors } = current;
          if (!result) {
            a.Fail.push(current);
            return a;
          }
          const { UMI, LVR, DTI, servicing } = result;
          const isLVRFail = LVR?.reduce(
            (isFail: any, lvrItem: any) => isFail || lvrItem.status === "Fail",
            false
          );
          if (
            (!servicing || servicing.type === "N/A"
              ? UMI.status === "Fail"
              : servicing.status === "Fail") ||
            isLVRFail ||
            DTI.Status === "Fail" ||
            errors?.some((error: any) => error.Reference === "Top")
          ) {
            a.Fail.push(current);
            return a;
          }
          a.Pass.push(current);
          return a;
        },
        {
          Pass: [],
          Fail: [],
        }
      );
      acc.push({
        Id: _case?.id,
        LoanAmount: _case?.loanAmount,
        Pass,
        Fail: Fail.sort((a: any) => {
          if (!a.errors?.some((error: any) => error.reference === "Top"))
            return -1;
          return 0;
        }),
      });
      return acc;
    },
    []
  );

  return loanAmountApproval;
};

export const detailsBuilderUI = (data: any) => {
  return [
    {
      title: "Application Name",
      value: data.applicant[0]?.name ?? "",
    },
    {
      title: "Spouse / Partner",
      value: data.applicant[1]?.name ?? "",
    },
    {
      title: "No. of Dependents",
      value: data.numberOfDependents,
    },
    {
      title: "No. of Owned Vehicles",
      value: data.numberOfVehicles,
    },
  ];
};

export const getTermsByMos = (monthCount: any) => {
  if (!monthCount) return "0 yrs";
  const month = monthCount % 12;
  const year = Math.floor(monthCount / 12);
  return (year ? `${year} yrs` : "") + (month ? ` ${month} mos` : "");
};

export const securityBuilderUI = (data: any) => {
  return [
    {
      title: "Security Type",
      value: data.security.securityType,
    },
    {
      title: "Value",
      value: currency(data.security.value, {
        precision: 0,
      }).format(),
    },
    {
      title: "Usage",
      value: data.security.usageType,
    },
    {
      title: "Property Type",
      value: data.security.propertyType?.reduce((acc: any, str: string) => {
        acc = Boolean(str) ? (Boolean(acc) ? `${acc} / ${str}` : str) : acc;
        return acc;
      }, ""),
    },
    {
      title: "RBNZ Exempt",
      value: Boolean(data.security.isRBNZExempt) ? "Yes" : "No",
    },
    {
      title: "Loan Type",
      value: data.loanType,
    },
    {
      title: "Loan Amount",
      value: currency(data.loanAmount, {
        precision: 0,
      }).format(),
    },
    {
      title: "Loan Term",
      value: getTermsByMos(data.totalTermMonths),
    },
    {
      title: "Interest Only Term",
      value: getTermsByMos(data.interestOnlyTermMonths),
    },
  ];
};

export const rentalIncomeBuilderUI = (data: any, applicants: Array<any>) => {
  const details = [
    {
      title: "Applicant",
      value:
        applicants?.find((_i: any) => _i.applicantId === data.applicantId)
          ?.name || "",
    },
    {
      title: "Amount",
      value: `${currency(data.amount, {
        precision: 2,
      }).format()} ${data.frequency}`,
    },
  ];

  if (
    data.attributes?.find((_i: any) => _i?.name === "PropertyCountry")?.value
  ) {
    details.push({
      title: "Property Country",
      value:
        data.attributes?.find((_i: any) => _i?.name === "PropertyCountry")
          ?.value || "",
    });
  }

  const propertyBuildDate = data.attributes?.find(
    (_i: any) => _i?.name === "PropertyBuildDate"
  )?.value;
  if (propertyBuildDate) {
    details.push({
      title: "Property Build Date",
      value: DateFns.isValid(new Date(propertyBuildDate ?? ""))
        ? DateFns.format(new Date(propertyBuildDate), "d MMM yyyy")
        : "",
    });
  }

  const salePurchaseDate = data.attributes?.find(
    (_i: any) => _i?.name === "SalePurchaseDate"
  )?.value;
  if (salePurchaseDate) {
    details.push({
      title: "Sale & Purchase Date",
      value: DateFns.isValid(new Date(salePurchaseDate ?? ""))
        ? DateFns.format(new Date(salePurchaseDate), "d MMM yyyy")
        : "",
    });
  }

  return details;
};

export const borderIncomeBuilderUI = (data: any, applicants: Array<any>) => {
  return [
    {
      title: "Applicant",
      value:
        applicants?.find((_i: any) => _i.applicantId === data.applicantId)
          ?.name || "",
    },
    {
      title: "Amount",
      value: `${currency(data.amount, {
        precision: 2,
      }).format()} ${data.frequency}`,
    },
    {
      title: "Border Type",
      value:
        data.attributes?.find((_i: any) => _i?.name === "BoarderIncomeType")
          ?.value || "",
    },
  ];
};

export const liabilityBuilderUI = (data: any) => {
  const details = [
    {
      title: "Liability Type",
      value: data.liabilityType,
    },
    {
      title: "Repayments",
      value: `${currency(data.repaymentAmount, {
        precision: 0,
      }).format()} ${data.repaymentFrequency}`,
    },
  ];

  if (data.limit) {
    details.push({
      title: "Limit",
      value: data.limit
        ? `${currency(data.limit, {
            precision: 2,
          }).format()}`
        : "",
    });
  }
  details.push({
    title: "Balance",
    value: data.balance
      ? `${currency(data.balance, {
          precision: 2,
        }).format()}`
      : "",
  });

  if (data.lender) {
    details.push({
      title: "Lender",
      value: data.lender,
    });
  }

  if (data.interestRate !== null) {
    details.push({
      title: "Interest Rate",
      value: `${
        typeof data.interestRate === "number"
          ? decimalToPercentage(data.interestRate)
          : data.interestRate
      } annually`,
    });
  }

  if (data.startDate !== null) {
    details.push({
      title: "Start Date",
      value: DateFns.isValid(new Date(data?.startDate ?? ""))
        ? DateFns.format(new Date(data.startDate), "d MMM yyyy")
        : "",
    });
  }

  if (data.totalTermMonths !== null) {
    details.push({
      title: "Loan Term",
      value: data.totalTermMonths ? `${data.totalTermMonths / 12} years` : 0,
    });
  }

  if (data.isRevolvingCredit !== null) {
    details.push({
      title: "Revolving Credit / Redraw Facility",
      value: Boolean(data.isRevolvingCredit) ? "Yes" : "No",
    });
  }

  return details;
};

export const salaryIncomeBuilderUI = (
  list: Array<any>,
  applicants: Array<any>
) => {
  return list?.reduce((acc, data) => {
    return [
      ...acc,
      {
        applicant: applicants?.find(
          (_i: any) => _i.applicantId === data.applicantId
        )?.name,
        type: data.amountType,
        amount: `${currency(data.amount, {
          precision: 0,
        }).format()} ${data.frequency}`,
        kiwisaver:
          data.attributes?.find((_i: any) => _i?.name === "Kiwisaver")
            ?.value === true
            ? "Yes"
            : "No",
      },
    ];
  }, []);
};

export const salaryOtherIncomeBuilderUI = (
  list: Array<any>,
  applicants: Array<any>
) => {
  return list?.reduce((acc, data) => {
    return [
      ...acc,
      {
        applicant: applicants?.find(
          (_i: any) => _i.applicantId === data.applicantId
        )?.name,
        type: data.incomeType,
        amount: `${currency(data.amount, {
          precision: 0,
        }).format()} ${data.frequency}`,
      },
    ];
  }, []);
};

export const outgoingCCBuilderUI = (list: Array<any>) => {
  return list?.reduce((acc, data) => {
    return [
      ...acc,
      {
        type: data.liabilityType,
        limit: `${currency(data.limit, {
          precision: 0,
        }).format()}`,
        lender: data.lender,
      },
    ];
  }, []);
};

export const expensesBuilderUI = (list: Array<any>, key: any) => {
  const filteredList = list.filter((_data: any) => _data.group === key);
  const newList = filteredList?.reduce((acc, data) => {
    return [
      ...acc,
      {
        type: data.label,
        amount: `${currency(data.amount, {
          precision: 2,
        }).format()} ${data.frequency}`,
      },
    ];
  }, []);
  return newList;
};

export const lenderLVRBuilderUI = (list: Array<any>) => {
  return list?.reduce((acc, data) => {
    return [
      ...acc,
      {
        type: data.label,
        description: data.description,
        maxLVR: `Max LVR: ${
          typeof data.maxLVR === "number"
            ? decimalToPercentage(data.maxLVR)
            : data.maxLVR
        }`,
      },
    ];
  }, []);
};

export const lenderDTIBuilderUI = (dti: any) => {
  return [
    {
      label: "Total Debt",
      value: `$${dti?.totalDebt}`,
    },
    {
      label: "Total Income",
      value: `$${dti?.totalIncome}`,
    },
    {
      label: "Debt to Income Ratio",
      value: dti?.value,
    },
  ];
};

export const lenderLEMBuilderUI = (lem: any) => {
  return [
    {
      label: "Assessed",
      value: `${
        typeof lem?.assessed === "number"
          ? decimalToPercentage(lem?.assessed)
          : lem?.assessed ?? "0%"
      }`,
    },
    {
      label: "Margin",
      value: `${
        typeof lem?.margin === "number"
          ? decimalToPercentage(lem?.margin)
          : lem?.margin ?? "0%"
      } p.a`,
    },
  ];
};

export const lenderUMIBuilderUI = (list: any) => {
  return list?.reduce((acc: any, data: any) => {
    return [
      ...acc,
      {
        label: data.label,
        entered: `${currency(data?.entered?.amount, {
          precision: 2,
        }).format()} p.m`,
        assessed: `${currency(data?.assessed?.amount, {
          precision: 2,
        }).format()} p.m`,
      },
    ];
  }, []);
};

export const lenderUMICalculatorBuilderUI = (umiResult: any) => {
  return [
    {
      label: "Total Income",
      value: `${currency(umiResult?.totalMonthlyIncome, {
        precision: 2,
      }).format()} p.m`,
    },
    {
      label: "Total Income",
      value: `${currency(umiResult?.totalMonthlyExpense, {
        precision: 2,
      }).format()} p.m`,
    },
    {
      label: "Bank Calculated UMI",
      value: `${currency(umiResult?.UMI?.value, {
        precision: 2,
      }).format()} p.m`,
    },
  ];
};
