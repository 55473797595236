import { FC } from "react";
import TableList from "./TableList";
import {
  lenderDTIBuilderUI,
  lenderLEMBuilderUI,
  lenderLVRBuilderUI,
  lenderUMIBuilderUI,
  lenderUMICalculatorBuilderUI,
} from "../lib/builder";
import * as DateFns from "date-fns";
import currency from "currency.js";
import { DTIIcon, WarningCircleSm } from "../../../icons";
import UMIIcon from "../../../icons/UMI";
import LVRIcon from "../../../icons/LVR";
import LEMIcon from "../../../icons/LEM";
import LenderErrors from "./LenderErrors";
import { twMerge } from "tailwind-merge";
import style from "../pdf-style.module.scss";
import LenderDetailTableList from "./LenderDetailTableList";

const LenderTable: FC<{
  data: any;
}> = (props) => {
  const { data } = props;
  // console.log(data, "<=== Lender Table data");
  const releaseDate = DateFns.isValid(new Date(data?.version?.releaseDate))
    ? DateFns.format(new Date(data?.version?.releaseDate), "d MMM yyyy")
    : "";
  const info =
    data?.version?.versionNumber && data?.version?.releaseDate
      ? `${data?.version?.versionNumber}` + releaseDate
        ? `(released ${releaseDate})`
        : ""
      : "";

  const hasTopError = !!data?.errors?.find((_i: any) => _i.reference === "Top")
    ?.message;

  const hasLVRErrors = Boolean(
    data?.errors?.filter((_i: any) => _i.reference === "Result.LVR")?.length ||
      data?.warnings?.filter((_i: any) => _i.reference === "Result.LVR")?.length
  );

  const hasUMIErrors = Boolean(
    data?.errors?.filter(
      (_i: any) =>
        _i.reference === "Result.Income" ||
        _i.reference === "Result.Servicing" ||
        _i.reference === "Result.Expenses"
    )?.length ||
      data?.warnings?.filter(
        (_i: any) =>
          _i.reference === "Result.Income" ||
          _i.reference === "Result.Servicing" ||
          _i.reference === "Result.Expenses"
      )?.length
  );

  return (
    <table style={{ width: "100%", border: 0 }}>
      <tbody style={{ border: 0 }}>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }}>
            <div
              className={style["lender-table"]}
              style={{ marginBottom: "20px" }}
            >
              <table className={style["lender-content"]}>
                <tbody>
                  <tr className={style["lender-header"]}>
                    <td align="left">
                      <p
                        className={style["lender-name"]}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <img
                          src={data?.lender?.logo}
                          alt="data?.Lender?.Name"
                        />
                        <span>{data?.lender?.name}</span>
                      </p>
                    </td>
                    <td align="right">
                      <p className={style["info"]}>{info}</p>
                    </td>
                  </tr>
                  {hasTopError ? (
                    <tr>
                      <td
                        className={twMerge(
                          style["lender-type"],
                          style["error"]
                        )}
                        colSpan={2}
                      >
                        {data?.errors?.map((_err: any, idx: number) => (
                          <p className={style["error"]} key={idx}>
                            <WarningCircleSm />
                            <span>{_err.message}</span>
                          </p>
                        ))}
                      </td>
                    </tr>
                  ) : (
                    <>
                      <tr>
                        <td
                          className={twMerge(
                            style["lender-type"],
                            hasUMIErrors && style["has-error"]
                          )}
                          width={120}
                          align="center"
                        >
                          <UMIIcon
                            className="mb-2"
                            fill={hasUMIErrors ? "#ff6e6e" : "#41b4b4"}
                          />
                          <p>
                            {currency(data?.result?.UMI?.value, {
                              precision: 2,
                            }).format()}{" "}
                            UMI
                          </p>
                        </td>
                        <td>
                          {/* UMI Incomes */}
                          {data?.result?.assessedIncome
                            ?.filter(
                              (value: any, index: any, self: any) =>
                                index ===
                                self.findIndex(
                                  (t: any) => t.group === value.group
                                )
                            )
                            .map((_item: any, idx: any) => {
                              return (
                                <LenderDetailTableList
                                  key={idx}
                                  title={`${_item?.group}`}
                                  column={[
                                    {
                                      title: "",
                                      key: "label",
                                    },
                                    {
                                      title: "",
                                      key: "entered",
                                      isAmount: true,
                                    },
                                    {
                                      title: "",
                                      key: "assessed",
                                      isAmount: true,
                                    },
                                  ]}
                                  data={lenderUMIBuilderUI(
                                    data?.result?.assessedIncome.filter(
                                      (_i: any) => _i.group === _item.group
                                    )
                                  )}
                                />
                              );
                            })}
                          <LenderErrors
                            reference="Result.Income"
                            errors={data?.errors}
                            warnings={data?.warnings}
                          />
                          {/* UMI Expenses */}
                          {data?.result?.assessedExpense
                            ?.filter(
                              (value: any, index: any, self: any) =>
                                index ===
                                self.findIndex(
                                  (t: any) => t.group === value.group
                                )
                            )
                            .map((_item: any, idx: any) => {
                              return (
                                <LenderDetailTableList
                                  key={idx}
                                  title={`${_item?.group} Expense`}
                                  column={[
                                    {
                                      title: "",
                                      key: "label",
                                    },
                                    {
                                      title: "",
                                      key: "entered",
                                      isAmount: true,
                                    },
                                    {
                                      title: "",
                                      key: "assessed",
                                      isAmount: true,
                                    },
                                  ]}
                                  data={lenderUMIBuilderUI(
                                    data?.result?.assessedExpense.filter(
                                      (_i: any) => _i.group === _item.group
                                    )
                                  )}
                                />
                              );
                            })}
                          <LenderErrors
                            reference="Result.Expenses"
                            errors={data?.errors}
                            warnings={data?.warnings}
                          />
                          <LenderDetailTableList
                            title={`SERVICING CALCULATOR`}
                            column={[
                              {
                                title: "",
                                key: "label",
                              },
                              {
                                title: "",
                                key: "",
                              },
                              {
                                title: "",
                                key: "value",
                                align: "right",
                                isAmount: true,
                              },
                            ]}
                            data={lenderUMICalculatorBuilderUI(data?.result)}
                            hideHead={true}
                          />
                          <TableList
                            title={``}
                            column={[
                              {
                                title: "Minimum UMI",
                                key: "type",
                              },
                              {
                                title: `${currency(
                                  data?.result?.UMI?.required,
                                  {
                                    precision: 2,
                                  }
                                ).format()}+ p.m`,
                                key: "maxLVR",
                                align: "right",
                                noRightSpace: true,
                              },
                            ]}
                            data={[]}
                          />
                          <LenderErrors
                            reference="Result.Servicing"
                            errors={data?.errors}
                            warnings={data?.warnings}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={twMerge(
                            style["lender-type"],
                            hasLVRErrors && style["has-error"]
                          )}
                          width={120}
                          align="center"
                        >
                          <LVRIcon
                            className="mb-2"
                            fill={hasLVRErrors ? "#ff6e6e" : "#41b4b4"}
                          />
                          <p>LVR</p>
                        </td>
                        <td>
                          {data?.result?.LVR?.map((_lvr: any, idx: any) => {
                            return (
                              <div key={idx}>
                                <TableList
                                  title={`Security ${idx + 1} - LVR`}
                                  badge={`${_lvr?.value * 100}%`}
                                  badgeClassname={
                                    hasLVRErrors ? "orange-badge" : "blue-badge"
                                  }
                                  column={[
                                    {
                                      title: "",
                                      key: "type",
                                    },
                                    {
                                      title: "",
                                      key: "description",
                                    },
                                    {
                                      title: "",
                                      key: "maxLVR",
                                      align: "right",
                                      noRightSpace: true,
                                      style: {
                                        whiteSpace: "nowrap",
                                      },
                                    },
                                  ]}
                                  data={lenderLVRBuilderUI(_lvr?.lvrLimit)}
                                  hideHead={true}
                                />
                                <TableList
                                  title={``}
                                  column={[
                                    {
                                      title: "Max LVR",
                                      key: "type",
                                    },
                                    {
                                      title: `${_lvr?.requiredMaxLVR * 100}%`,
                                      key: "maxLVR",
                                      align: "right",
                                      noRightSpace: true,
                                    },
                                  ]}
                                  data={[]}
                                />
                                <LenderErrors
                                  reference="Result.LVR"
                                  errors={data?.errors}
                                  warnings={data?.warnings}
                                  loanId={_lvr.loanId}
                                />
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={style["lender-type"]}
                          width={120}
                          align="center"
                        >
                          <DTIIcon className="mb-2" fill="#41b4b4" />
                          <p>DTI</p>
                        </td>
                        <td>
                          <TableList
                            title={`DTI`}
                            column={[
                              {
                                title: "",
                                key: "label",
                              },
                              {
                                title: "",
                                key: "value",
                                align: "right",
                                noRightSpace: true,
                              },
                            ]}
                            data={lenderDTIBuilderUI(data?.result?.DTI)}
                            hideHead={true}
                          />
                          <TableList
                            title={``}
                            column={[
                              {
                                title: "Max DTI",
                                key: "label",
                              },
                              {
                                title: data?.result?.DTI?.requiredMaxDTI,
                                key: "value",
                                align: "right",
                                noRightSpace: true,
                              },
                            ]}
                            data={[]}
                          />
                          <LenderErrors
                            reference="Result.DTI"
                            errors={data?.errors}
                            warnings={data?.warnings}
                          />
                        </td>
                      </tr>
                      {Boolean(data?.result?.LEM?.length) && (
                        <tr>
                          <td
                            className={twMerge(
                              style["lender-type"],
                              style["lender-lem"]
                            )}
                            width={120}
                            align="center"
                          >
                            <LEMIcon className="mb-2" fill="#1d1c1d" />
                            <p>LEM</p>
                          </td>
                          <td>
                            {data?.result?.LEM?.map((_lem: any, idx: any) => (
                              <div key={idx}>
                                <TableList
                                  title={`Security ${idx + 1} - LEM`}
                                  badge={`${
                                    _lem.amount
                                      ? currency(_lem.amount, {
                                          precision: 2,
                                        }).format()
                                      : ""
                                  }`}
                                  column={[
                                    {
                                      title: "",
                                      key: "label",
                                    },
                                    {
                                      title: "",
                                      key: "value",
                                      align: "right",
                                      noRightSpace: true,
                                    },
                                  ]}
                                  data={lenderLEMBuilderUI(_lem)}
                                  hideHead={true}
                                />
                                <TableList
                                  title={``}
                                  column={[
                                    {
                                      title: "LEM Amount",
                                      key: "label",
                                    },
                                    {
                                      title: `${currency(_lem.amount ?? 0, {
                                        precision: 2,
                                      }).format()}`,
                                      key: "value",
                                      align: "right",
                                      noRightSpace: true,
                                    },
                                  ]}
                                  data={[]}
                                />
                              </div>
                            ))}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LenderTable;
