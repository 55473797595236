import { FC } from "react";
import { twMerge } from "tailwind-merge";
import style from "../pdf-style.module.scss";
import cx from "classnames";

const LenderDetailTableList: FC<{
  title: string;
  data: Array<any>;
  column: any;
  style?: any;
  isAmount?: boolean;
  hideHead?: boolean;
}> = (props) => {
  return (
    <>
      <div style={props.style}>
        <table className={cx(style["table-detail-list"])}>
          <tbody>
            <tr className={style["head-title"]}>
              <td>
                <p>{props.title}</p>
              </td>
              <td className={style["amount"]} align="right">
                {!props?.hideHead && <p>Entered</p>}
              </td>
              <td className={style["amount"]} align="right">
                {!props?.hideHead && <p>Assessed</p>}
              </td>
            </tr>
            {props.data?.map((_data: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {props.column?.map((col: any, colIndex: number) => (
                  <td
                    key={colIndex}
                    className={twMerge(col?.isAmount && style["amount"])}
                    align={col?.isAmount && "right"}
                  >
                    <p>{_data[col?.key]}</p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LenderDetailTableList;
