import { FC, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { ILoanAmountApproval } from ".";
import currency from "currency.js";

const formatToCash = (num: number) => {
  return (
    "$" +
    Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 2,
    }).format(num)
  );
};

const LoanAmountApprovalBar: FC<{
  loanAmountApproval: ILoanAmountApproval[];
  activeLenders: ILoanAmountApproval | null;
  onBarClick?: (d: ILoanAmountApproval) => void;
  isForPDF?: boolean;
}> = ({ loanAmountApproval, activeLenders, onBarClick, isForPDF = false }) => {
  const { Fail, Pass } = loanAmountApproval[0] || {};
  const totalLenders = (Fail?.length || 0) + (Pass?.length || 0);
  const dataLoansAmount = loanAmountApproval.map((d) => d.LoanAmount);

  const svgRef = useRef<SVGSVGElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  useEffect(() => {
    const width = containerWidth;
    const height = 131;
    const margin = {
      top: 12,
      left: 24,
      bottom: 24,
      right: 16,
    };

    if (!width) return;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [0, 0, width, height + margin.bottom + margin.top]);

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScaleApprove = d3
      .scaleLinear()
      .domain([0, totalLenders > 4 ? totalLenders : 4])
      .range([height / 2, 0]);
    const yScaleDecline = d3
      .scaleLinear()
      .domain([0, totalLenders > 4 ? totalLenders : 4])
      .range([height / 2, height]);

    const xScale = d3
      .scaleBand()
      .domain(dataLoansAmount.map(String))
      .range([0, width - margin.right - margin.left + 4])
      .paddingInner(0.7);

    // AXIS
    const yAxisApprove = d3.axisLeft(yScaleApprove).ticks(2);
    const yAxisDecline = d3.axisLeft(yScaleDecline).ticks(2);
    const xAxis = d3.axisBottom(xScale).tickFormat((value, index) => {
      let longestLabelLength = 0;

      for (const x of xScale.domain()) {
        const formatted = formatToCash(Number(x));
        longestLabelLength =
          formatted.length > longestLabelLength
            ? formatted.length
            : longestLabelLength;
      }

      if (longestLabelLength > 7 && index % 2 !== 0) {
        return "";
      }

      return formatToCash(Number(value));
    });
    const barWidth = xScale.bandwidth();

    chart
      .append("g")
      .call(yAxisApprove)
      .call((g) => {
        g.selectAll("line").remove();
        g.select(".domain").remove();
        g.selectAll("text")
          .style("color", "#42B4B4")
          .style("font", `10px "Helvetica", sans-serif`);

        g.select("text").style("color", "#AEAEAE");
      });
    chart
      .append("g")
      .call(yAxisDecline)
      .call((g) => {
        g.selectAll("line").remove();
        g.select(".domain").remove();
        g.selectAll("text")
          .style("color", "#F18383")
          .style("font", `10px "Helvetica", sans-serif`);
        g.select("text").remove();
      });
    chart
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis)
      .call((g) => {
        g.selectAll("line").remove();
        g.select(".domain").remove();
        g.selectAll("text")
          .style("color", "#AEAEAE")
          .style("font", `Helvetica", sans-serif`)
          .style("font-size", `10px`);
      });

    // GRID BORDER
    chart
      .append("g")
      .attr("opacity", 0.3)
      .call(yAxisApprove.ticks(5))
      .call((g) => {
        // REMOVE THE TEXT AND AXIS
        g.select(".domain").remove();
        g.selectAll("text").remove();

        // ADD THE LINE
        g.selectAll(".tick")
          .select("line")
          .attr("stroke", "#AEAEAE")
          .attr("stroke-dasharray", "2")
          .attr("x2", width - margin.right - margin.left + barWidth / 2);
      });

    chart
      .append("g")
      .attr("opacity", 0.3)
      .call(yAxisDecline.ticks(5))
      .call((g) => {
        // REMOVE THE TEXT AND AXIS
        g.select(".domain").remove();
        g.selectAll("text").remove();

        // ADD THE LINE
        g.selectAll(".tick")
          .select("line")
          .attr("stroke", "#AEAEAE")
          .attr("stroke-dasharray", "2")
          .attr("x2", width - margin.right - margin.left + barWidth / 2);

        // REMOVE THE MIDDLE LINE
        g.select(".tick").remove();
      });

    const isActive = (Id: number) => activeLenders?.Id === Id;
    const getBarX = (d: ILoanAmountApproval) =>
      xScale(d.LoanAmount.toString()) || 0;

    // GRAPH / BARS
    const bars = chart
      .selectAll("path")
      .data(loanAmountApproval)
      .enter()
      .append("g")
      .on("mouseover", function (_, d) {});

    bars
      .append("path")
      .attr("id", `approvebar`)
      .attr("fill", (d) => (isActive(d.Id) ? "#42B4B4" : "#C9E7E7"))
      .attr("d", (d) => {
        const scale = barWidth * 0.25;
        const x = getBarX(d) - (isActive(d.Id) ? scale : 0);
        const y = height / 2 - 0.5;
        const w = barWidth / 2 + (isActive(d.Id) ? scale : 0);
        const h = height / 2 - yScaleApprove(d.Pass.length) - 0.5;

        const path = [
          `M ${x},${y}`,
          `v ${-h + w}`,
          `q 0,${-w} ${w},${-w}`,
          `h 0`,
          `q ${w},0 ${w},${w}`,
          `v ${h - w}`,
          `z`,
        ];

        return path.join(" ");
      });

    bars
      .append("path")
      .attr("id", `declinebar`)
      .attr("fill", (d) => (isActive(d.Id) ? "#F18383" : "#FFD7D7"))
      .attr("d", (d) => {
        const scale = barWidth * 0.25;
        const x = getBarX(d) - (isActive(d.Id) ? scale : 0);
        const y = height / 2 + 0.5;
        const w = barWidth / 2 + (isActive(d.Id) ? scale : 0);
        const h = yScaleDecline(d.Fail.length) - height / 2;

        const path = [
          `M ${x},${y}`,
          `v ${h - w}`,
          `q 0,${w} ${w},${w}`,
          `h 0`,
          `q ${w},0 ${w},${-w}`,
          `v ${-h + w}`,
          `z `,
        ];

        return path.join(" ");
      });

    bars
      .append("circle")
      .attr("r", (d) => (isActive(d.Id) ? 6 : 0))
      .attr("cx", (d) => getBarX(d) + barWidth / 2)
      .attr("cy", height / 2)
      .attr("stroke", "#fff")
      .attr("strokeWidth", 3)
      .attr("fill", "#393939");

    const tooltip = d3
      .select(containerRef.current)
      .selectAll("div")
      .data(loanAmountApproval)
      .enter()
      .append("div")
      .attr("id", (d) => `tooltip${d.LoanAmount}`)
      .style(
        "top",
        `${height / 2 + (isForPDF ? margin.top + 10 : margin.top)}px`
      )
      .style(
        "left",
        (d) =>
          `${
            getBarX(d) +
            margin.left +
            (isForPDF ? -(barWidth / 0.4) : barWidth / 2)
          }px`
      )
      .style("transform", "translate(-50%, 8px)")
      .style("opacity", (d) => (isActive(d.Id) ? 1 : 0))
      .style("pointer-events", "none")
      .attr(
        "class",
        "absolute bg-[#393939] text-white font-bold font-helvetica text-[11px] px-2 rounded-[3px]"
      );

    tooltip
      .append("div")
      .attr(
        "class",
        "bg-transparent absolute bottom-[calc(100%-1px)] left-1/2 -translate-x-1/2"
      )
      .style("border-left", "6px solid transparent")
      .style("border-right", "6px solid transparent")
      .style("border-bottom", "4px solid #393939");

    tooltip
      .append("span")
      .text((d) => currency(d.LoanAmount, { precision: 0 }).format())
      .style("background-color", "#393939")
      .style("color", "#ffffff")
      .style("font-weight", "700")
      .style("font-size", "11px")
      .style("border-radius", "3px")
      .style("padding", "0 5px");

    return () => {
      chart.remove();
      tooltip.remove();
    };
  }, [
    activeLenders?.Id,
    containerWidth,
    dataLoansAmount,
    loanAmountApproval,
    onBarClick,
    totalLenders,
    isForPDF,
  ]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resize = () => {
      setContainerWidth(container.offsetWidth);
    };
    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative max-w-full">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default LoanAmountApprovalBar;
